.stores-container {
  display: flex;
  padding-top: 96px;
  margin-top: 72px;
  flex-direction: row;
  gap: 0;
  overflow-y: hidden;
  margin: 0;
  font-family: 'Work Sans', sans-serif;
}

.list-padding {
  padding-top: 10px;
}

.stores-list {
  flex: 0 0 50%;
  overflow-y: hidden;
  max-width: 50%; 
  min-width: 200px; 
  padding-left: 20px;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #00443B;
  margin-top: -20px; /* Adjust this value to extend the border into the margin */
  padding-top: 20px; /* Add padding to compensate for the negative margin */
} 

.stores-map,
.store-details{
  flex: 1;
  max-width: none;
  min-width: 0; 
  padding: 0;
  box-shadow: none;
  font-family: 'Work Sans', sans-serif;
}

.stores-map {
  border-radius: 1; 
}

.state-header {
  display: flex;

  align-items: center;
  cursor: pointer;
  font: var(--font-small-title);
  letter-spacing: var(--font-small-title-spacing);
  color: #27453e;
  padding: 10px 0;
}

.toggle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 35px;
  margin-left: 0px;
  cursor: pointer;
}

.toggle-icon img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.toggle-icon img.expanded {
  transform: rotate(180deg);
}

.state-stores {
  padding-left: 20px;
  font-family: 'Work Sans', sans-serif;
}

.state-header {
  letter-spacing: 1px;
}

.store-title {
  font: var(--font-menu);
  letter-spacing: var(--font-menu-letter-spacing);
  color: #27453e;
  margin-bottom: 10px;
}

.store-item:hover .store-number,
.store-item:hover .store-title,
.store-item:hover .store-address,
.store-item:hover .store-phone  {
  color: #9DBC97;
  transition: color 0.3s ease;
}

.store-address, .store-phone {
  font: var(--font-medium-body);
  letter-spacing: var(--font-medium-body-letter-spacing);
  letter-spacing: 1px;
  color: #27453e;
  margin-bottom: 10px;
}

.store-item {
  display: flex;
  gap: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: flex-start;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.store-number {
  background-color: #27453e;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  font-family: 'Work Sans', sans-serif;
}

.store-phone {
  font: var(--font-medium-body);
  letter-spacing: var(--font-medium-body-letter-spacing);
}

.no-stores {
  font-size: 0.9em;
  color: #6d6d6d;
}

hr {
  border: none;
  margin: 10px 0;
  margin-right: 40px;
}

/* Add these media queries for responsive design */
@media screen and (max-width: 768px) {
  .stores-container {
    flex-direction: column;
    overflow-y: auto; /* Allow scrolling for long viewports */
  }

  .stores-list {
    padding-bottom: 20px;
    border-bottom: 1px solid #00443B;
    flex: auto;
    max-height: calc(100vh - 96px); /* Ensure the list fits within the viewport */
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
  }

  .stores-map {
    display: none;  /* Hide map on mobile */
  }

  /* Adjust store list styling for mobile */
  .store-item {
    gap: 20px;
  }
}
