.products-wrapper {
    background-color: #FFFEF1;
    position: relative;
}

.products-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 725px;
    width: 100%;
    background-color: #FFFEF1;
    padding: 0 20px;
}

.products-title {
    font: var(--font-medium-title);
    letter-spacing: var(--font-medium-title-spacing);
    color: #00443B;
    text-align: center;
    margin: 0 0 40px 0; /* Reduced from 20px 0 50px 0 */
    letter-spacing: 2px;
    margin-top: 10px;
}

.title-section {
    text-align: center;
    padding: 0;
    margin-top: 0; /* Reduced from 30px */
}

.title-divider {
    height: 1px;
    background-color: #00443B;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
}

.product-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 60px;
    position: relative; /* Added to contain the scaled image */
    border: none;
    border-left: none;
    border-right: none;
}

.product-image {
    width: auto;
    object-fit: contain;
    margin-top: 0;
    transform: scale(1.6);
    transform-origin: center 20%;  /* Move origin up to prevent top cropping */
    position: relative;
    max-height: 85%;
    overflow: visible;
    z-index: 1;
}

.product-title {
    font-family: 'WorkSansMedium', sans-serif;
    font-size: 25px;
    font-weight: 400;
    color: #00443B;
    text-transform: uppercase;
    margin-bottom: 0; /* Removed 95px margin */
    margin-top: 30px; /* Add this to align with preview titles */
}

.products-footer-text {
    font: var(--font-large-body);
    letter-spacing: var(--font-large-body-letter-spacing);
    color: #00443B;
    text-align: center;
    max-width: 1000px;  /* Changed from 1200px to match store description */
    margin: 0 auto;
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    z-index: 2;
    white-space: pre-wrap; /* Ensures line breaks are respected */
    word-wrap: break-word; /* Prevents long words from breaking layout */
}

.footer-text-desktop {
    display: block;
}

.footer-text-mobile {
    display: none;
}

.slider-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-top: 0; /* Removed margin */
    z-index: 2;
    border: none;
    border-left: none;
    border-right: none;
}

.slider-chevron {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    transition: opacity 0.3s ease;
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    z-index: 10;
}

.slider-chevron.left {
    left: 15px;
}

.slider-chevron.right {
    right: 15px;
}

.slider-chevron img {
    height: 32px; /* Adjust height as needed */
    width: auto;
}

.slider-chevron.hidden {
    opacity: 0;
    pointer-events: none;
}

.slider-chevron:hover {
    opacity: 0.7;
}

.preview-title {
    position: absolute;
    font-family: 'WorkSansMedium', sans-serif;
    font-size: 16px;
    color: #00443B;
    opacity: 0.5;
    pointer-events: auto;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    z-index: 2;
}

.preview-title.left {
    display: none;
}

.preview-title.right {
    display: none;
}


/* Ensure the preview titles are at the same vertical position as the selected item */
.slider-item {
    flex: 0 0 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 0 0px;
    position: relative;
    overflow: visible; /* Allow content to overflow for scaling */
    border: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
}

.slider-item::before,
.slider-item::after {
    display: none;
    content: none;
}

.slider-viewport {
    overflow: hidden;
    width: 80%;
    max-width: 800px;
    padding-bottom: 0;
    position: relative;
    border: none;
    max-height: 650px;  /* Reduced from 800px */
}

.slider-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
    overflow: visible; /* Allow content to overflow for scaling */
    border: none;
    border-left: none;
    border-right: none;
}

.product-divider,
.vertical-divider,
.slider-divider {
    display: none;
}

.product-titles-row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px; /* Increased from 60px */
    margin-bottom: 90px;
    margin-top: 70px; /* Reduced from 40px */
    display: none;
    letter-spacing: 1px;
}

.desktop-product-title {
    font: var(--font-small-title);
    letter-spacing: var(--font-small-title-spacing);
    color: #00443B;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.desktop-product-title.active {
    opacity: 1;
}

@media (max-width: 767px) {

    .desktop-product-title {
        display: block; /* Show only the title for the selected product */
        font-size: 16px; /* Adjust font size for better visibility on mobile */
        text-align: center; /* Center align the text for mobile */
        margin-top: 10px; /* Add spacing above the title */
    }


    .slider-item {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        padding-top: 0;
        overflow: visible;   /* Allow overflow */
        min-height: 500px;   /* Reduced from 550px */
        text-align: center; /* Ensure text is centered */
    }

    .slider-item .product-title {
        display: block; /* Ensure the product title is visible */
        font-size: 16px; /* Adjust font size for better visibility */
        color: #00443B; /* Match the color scheme */
        margin-top: 35px; /* Decreased spacing between image and title */
    }

    .products-title {
        font-size: 15pt;
        margin: -90px 0 40px 0;  /* Reduced from 35px */
    }
    
    .title-divider {
        width: 85%;
    }

    .products-footer-text {
        font-family: 'Work Sans', sans-serif;
        color: #00443B;
        font-size: 25px;
        padding-top: 50px;    /* Reduced from 100px */
        padding-bottom: 40px; /* Reduced from 50px */
        line-height: 1.5; /* Ensure long words break properly */
    }

    .slider-viewport {
        width: 90%;
        padding-bottom: 0;
        max-height: 500px;  /* Reduced */
        overflow: hidden;
    }

    .preview-title.left {
        left: 0px;
        top: 65px;           /* Reduced from 45px */
    }

    .preview-title.right {
        right: 0px;
        top: 65px;           /* Reduced from 45px */
    }

    .product-title {
        font-size: 20px;
        margin-top: 0;       /* Reduced from 2px */
    }

    .product-image {
        height: 340px;
        transform: scale(1.8);
        margin-top: 0;
        transform-origin: center 40%;  /* Move origin up more on mobile */
        max-height: none;
        z-index: 1;
    }

    .slider-chevron.left {
        left: 2px;
        top: 40%;
        transform: scale(0.7);             /* Adjusted from 60% */
    }

    .slider-chevron.right {
        right: 2px;
        top: 40%;
        transform: scale(0.7);           /* Adjusted from 60% */
    }

    .footer-text-desktop {
        display: none;
    }

    .footer-text-mobile {
        display: block;
    }

    .desktop-product-title {
        display: block; /* Ensure the product title is visible on mobile */
        font-size: 16px; /* Adjust font size for better visibility on mobile */
        text-align: center; /* Center align the text for mobile */
    }

    .desktop-product-title.active {
        font-weight: bold; /* Highlight the active product title */
    }
}

@media (min-width: 768px) {

    .preview-title {
        display: none;
    }

    /* Make the selector more specific to only target product-title within Products component */
    .products-wrapper .product-title {
        display: none;
    }

    .product-image {
        transform: scale(1.6); /* Match the scale from above */
        padding-bottom: 150px;
    }

    .slider-viewport {
        width: 80%;
        max-width: 800px;
    }

    .slider-chevron.left {
        left: 15px;
    }

    .slider-chevron.right {
        right: 15px;
    }

    .product-titles-row {
        display: flex; /* Ensure visibility on desktop */
    }
}
