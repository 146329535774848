.product-info {
    width: 100%;
    padding: 0 35px 35px;
    box-sizing: border-box;
}

h2.product-title {
    font: var(--font-small-title);
    letter-spacing: var(--font-small-title-spacing);
    text-align: center;
    padding-top: 80px;
    padding-bottom: 50px;
    color: #00443B;
    line-height: 1.5;
}

.product-image {
    width: 75%; /* Reduced from 85% */
    height: auto;
    max-height: 380px; /* Reduced from 440px */
    object-fit: contain;
    display: block;
    margin: 0 auto; /* Center the image */
}

.image-container {
    position: relative;
    width: 40%;
    margin: 45px auto 120px; /* Added bottom margin for spacing */
    z-index: 1;
}

.description-container {
    position: absolute;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.description-container.top {
    right: 75%;
    top: -10px;
}

.description-container.bottom {
    right: 75%;
    top: auto;
    bottom: -10px;
}

.description-container.top-right {
    left: 75%;
    top: -10px;
    z-index: 2;
}

.description-container.bottom-right {
    left: 75%;
    top: auto;
    bottom: -10px;
    z-index: 2;
}

.description-text {
    font: var(--font-medium-body);
    letter-spacing: var(--font-medium-body-letter-spacing);
    text-align: right;
    color: #00443B;
    padding-right: 20px;
    width: 250px;
}

.description-text.right {
    text-align: left;
    padding-right: 0;
    padding-left: 20px;
}

.pointing-stroke {
    position: relative;
    width: 10px;
    height: 1px;
    background-color: #00443B;
    margin-top: 12px;
    padding-left: 40px;
    padding-right: 20px;
}

.pointing-stroke.right {
    transform: scaleX(-1);
}

.mobile-descriptions {
    display: none;
}

@media screen and (max-width: 768px) {
    h2.product-title { 
        padding-top: 150px;
        font-size: 24px !important;
    }

    .pointing-stroke {
        display: none;
    }

    .description-container {
        display: none;
    }

    .mobile-descriptions {
        display: grid;
        grid-template-columns: 1.3fr 1fr;  /* Make left column slightly wider */
        grid-template-rows: repeat(2, auto);
        gap: 15px 90px;  /* Increased column gap from 50px to 80px */
        margin: 80px auto;
        width: 111%;  /* Reduced from 111% to help with centering */
        max-width: 600px;  /* Increased from 500px */
        position: relative;
        left: -5%;  /* Shift slightly left to compensate for uneven spacing */
    }

    .mobile-description-text {
        font-family: 'SimplonMono', monospace;
        font-size: 14px;
        text-align: left;
        color: #00443B;
        line-height: 1.5;
        grid-row: auto; /* Reset any implicit row positioning */
        margin: 0; /* Remove any margin that might affect positioning */
        width: 120%;  /* Ensure text takes full width of its grid cell */
    }

    .mobile-description-text.left-column {
        grid-column: 1;
        grid-row: 1 / span 2;  /* Span two rows */
    }

    .mobile-description-text.right-column:first-child {
        grid-column: 2;
        grid-row: 1;
    }

    .mobile-description-text.right-column:nth-child(2) {
        grid-column: 2;
        grid-row: 2;
    }

    .image-container {
        width: 180%;
        margin: 10px auto 80px; /* Added bottom margin for mobile */
        transform: translateX(-22%);
        max-height: none;
        overflow: visible;
    }
}
