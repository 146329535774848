.sustainability-container {
    padding-top: 89px; /* Match header height exactly */
}

.sustainability-top {
    background-color: #8FBE97;
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
}

.sustainability-bottom {
    background-color: #FFFEF1;
    width: 100%;
    padding: 35px;
    padding-bottom: 35px; /* Add back bottom padding */
    box-sizing: border-box;
}

.video-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensure padding is included in height calculation */
    position: relative;
    overflow: hidden;
}

.sustainability-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures aspect ratio is maintained */
    display: block;
}

.sustainability-title {
    font: var(--font-medium-title);
    letter-spacing: --font-medium-title-spacing;
    margin: 35px 0 0 0;
    padding: 0;
    text-align: center;
    color: white;
}

.sustainability-divider {
    width: 90%;
    height: 1px;
    background-color: white;
    margin: 35px auto 0;
}

.sustainability-divider-alt {
    width: 100%;
    height: 1px;
    background-color: #00443B;
    margin: 5px auto 0;
}

.sustainability-divider:last-child {
    background-color: #00443B;
}

.sustainability-quote {
    margin: 0;
    text-align: center;
    color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
}

.sustainability-quote p:not(.quote-source) {
    font: var(--font-large-body);
    margin: 0;
    line-height: 1.4;
}

.sustainability-quote .quote-source {
    font-family: 'SimplonMono', monospace;
    font-size: 10px;
    margin-top: auto;
    padding-top: 50px;
    margin-bottom: 0;
}

.sustainability-statement {
    flex: 1;
    text-align: left;
    padding: 10px 0 35px 0; /* Change to have equal padding top and bottom (35px) */
    padding-right: 25px; /* Remove right padding */
    margin-bottom: 0; /* Ensure no bottom margin */
}

.sustainability-bottom .content-columns {
    margin-top: 0; /* Remove top margin from columns in bottom section */
}

.sustainability-statement p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 3.9vw; /* Replace 45pt with responsive size */
    color: #00443B;
    line-height: 1.4; /* Double the current line height */
    margin: 0;
}

.content-columns {
    display: flex;
    margin: 35px 0 0 0;
}

.issue-column {
    padding: 0 70px;
    color: white;
}
 
.sustainability-bottom .issue-column {
    padding: 0 70px 0 0; /* Remove left padding, keep right padding */
}

.issue-column p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500; /* Medium */
    font-size: 18px;
    margin: 0;
}

.sustainability-rows-container {
    margin-top: 0; /* Reduce from 50px to 0 to eliminate the gap */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0; /* No gap between rows */
    position: relative; /* Ensure this container has its own stacking context */
    z-index: 1; /* Place above other elements */
    clear: both; /* Clear any floats */
    background-color: #FFFEF1;
}

/* Additional fix to ensure rows appear correctly */
.sustainability-row:first-child {
    margin-top: 0; /* No margin for first row */
}

.final-divider {
    width: calc(100% + 70px); /* Extend width to compensate for negative margin */
    height: 1px;
    background-color: #00443B;
    margin-top: 35px;
    margin-left: -70px; /* Pull the divider left to align with the text */
}

.mobile-quote {
    display: none;
}

/* Move media query here, after the base styles */
@media (max-width: 768px) {
    .sustainability-top {
        padding: 0; /* Remove padding for full bleed */
    }

    .video-container {
        height: 78vh; /* Reduced from 60vh by 20% */
        width: 100vw; /* Full viewport width */
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;
    }

    .sustainability-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .sustainability-top .sustainability-title {  /* Make selector more specific */
        position: relative;
        z-index: 2;
        font-weight: 100;
        font-size: 15pt !important; /* Add !important to ensure override */
    }

    .desktop-quote {
        display: none;
    }
    
    .mobile-quote {
        display: block;
        font-family: 'Work Sans', sans-serif;
        font-size: 5pt;
        line-height: 1.4;
        margin: 0;
        padding-top: 5px;
    }
    
    .sustainability-quote {
        padding: 0px 15px 20px 15px;  /* Reduced from 35px to 15px horizontal padding */
    }

    .sustainability-quote p.mobile-quote {  /* Single, more specific selector */
        display: block;
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;
        font-size: 18pt !important;
        line-height: 1.4;
        margin: 0;
        padding-bottom: 15px;
    }
}
