@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

:root {
  --footer-bg: #00443B;
  --footer-text: #FFFEF1;
  --footer-padding: 20px 10%;
  --footer-mobile-link-size: 16px;
}

.footer {
  background-color: var(--footer-bg);
  color: var(--footer-text);
  padding: var(--footer-padding);
  font-family: var(--footer-font);
  font-weight: 500;
  position: relative;
  z-index: 10;
  overflow: hidden; /* Add this */
}

.footer-container {
  display: flex;
  justify-content: space-between; /* Change from space-around */
  align-items: flex-start;
  flex-wrap: nowrap; /* Prevent wrapping */
  max-width: 1200px;
  margin: 12px auto;
  gap: 50px; /* Add consistent gap between columns */
}

/* Restore desktop footer section sizing */
.footer-section {
  flex: 1; /* Simplify flex value */
  padding: px 10px; /* Reduce padding */
  min-width: 0; /* Allow columns to shrink if needed */
  text-align: left;
}

.footer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left; /* Ensure left alignment */
}

.footer-link {
  color: var(--footer-text);
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  margin-bottom: 12px;
  font-size: var(--font-menu);
  letter-spacing: var(--font-menu-letter-spacing);
  transition: opacity 0.2s ease;
}

.footer-link:hover {
  opacity: 0.8;
}

.footer-social {
  flex: 1;
  margin-top: 0;
  padding-top: 0; /* Remove extra padding */
  position: relative;
  text-align: left; /* Align with other columns */
}

/* Remove the ::before pseudo-element from footer-social */
.footer-social::before {
  display: none;
}

/* Restore and modify the footer-divider */
.footer-divider {
  display: none; /* Hidden by default on desktop */
  position: absolute;
  left: -100vw;
  right: -100vw;
  height: 1px;
  background-color: #FFFEF1;
}

/* Move social section styles here from mobile */
.footer-social .footer-text {
  margin-bottom: 12px; /* Reduced from 20px */
}

.footer-icon-link {
  color: var(--footer-text);
  font-size: 24px;
  margin: 0 0 8px 0; /* Reduced from 12px 0 */
  display: inline-block;
}

.footer-email {
  color: var(--footer-text);
  text-decoration: none;
  font: var(--font-small-body);
  display: block;
  margin-top: 8px; /* Reduced from 12px */
}

.footer-social-icon {
  width: 34px; /* Reduced by 30% from 48px */
  height: 34px; /* Reduced by 30% from 48px */
  filter: brightness(0) invert(1); /* Makes the icon white */
  transition: opacity 0.2s ease;
}

.footer-icon-link:hover .footer-social-icon {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .footer {
    padding: 40px 20px;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    min-height: 200px; /* Add minimum height to ensure vertical space */
  }

  .footer-social {
    flex: 1 1 100%;
    order: -1;
    margin: 0 auto;  /* Add auto margins */
    padding: 40px 0; /* Remove horizontal padding */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-divider {
    display: block; /* Show the full-width divider on mobile */
    left: -100vw;
    right: -100vw;
    position: absolute;
    bottom: -20px;
    width: 200vw; /* Ensure full width */
    margin-left: 0; /* Reset any margins */
    margin-right: 0; /* Reset any margins */
  }

  .footer-section:not(.footer-social) {
    margin-top: 40px;
  }

  .footer-section:not(.footer-social) {
    margin-top: 50px; /* Add more space after the line */
  }

  .footer-section {
    flex: 1 1 100%; /* Full width on mobile */
    padding: 10px 20px;
  }

  .footer-list {
    display: grid;
    grid-template-columns: 1fr;  /* Change to single column on mobile */
    gap: 16px;
    text-align: left;
  }

  .footer-link,
  .footer-email {
    font-size: var(--footer-mobile-link-size);
    margin: 0;
  }

  .footer-social .footer-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center; /* Center flex items */
    text-align: center; /* Center text inside flex items */
    width: 100%;
  }

  .footer-social .footer-text,
  .footer-social .footer-email,
  .footer-social .footer-icon-link {
    margin: 20px 0;
    padding: 0;  /* Remove any padding */
    width: fit-content;  /* Let content determine width */
    text-align: center;
    position: relative;  /* Add relative positioning */
    left: -25px;  /* Reset any left offset */
    transform: none;  /* Remove any transforms */
  }

  .footer-social-icon {
    width: 56px; /* Keep mobile size unchanged */
    height: 56px;
  }

  .footer-social::before {
    display: none; /* Hide the desktop divider on mobile */
  }
}
