.eco-packaging {
    width: 100%;
    background-color: #8FBE97;
    padding: 50px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.eco-packaging-image {
    max-width: 80%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.eco-packaging-title {
    font: var(--font-medium-title);
    color: white;
    margin: 0 0 25px 0;
    text-align: center;
}

.eco-packaging .content-columns {
    width: 100%;
    padding: 35px 0;  /* Reduced from 70px */
    margin: 0;
    display: flex;
}

.eco-packaging .sustainability-quote {
    margin: 0;
}

.eco-packaging .sustainability-quote p.desktop-quote {
    font: var(--font-large-body);
    letter-spacing: var(--font-large-body-letter-spacing);
    margin: 0;
}

.mobile-quote {
    display: none;
}


@media (max-width: 768px) {
    .eco-packaging {
        padding: 35px;  /* Reduce from 50px to match other spacing */
    }

    .eco-packaging-image {
        max-width: 90%;
    }
    
    .eco-packaging-title {
        font-size: 15pt !important;
    }

    .desktop-quote {
        display: none;
    }
    
    .eco-packaging .sustainability-quote p.mobile-quote {  /* More specific selector */
        display: block;
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;
        font-size: 18pt !important;
        line-height: 1.4;
        margin: 0;
        padding-top: 35px;
    }

    .eco-packaging .content-columns {
        padding: 0;  /* Remove padding since we're handling it in the quote */
    }

    .eco-packaging .sustainability-quote {
        padding: 0 5px;  /* Reduced from 35px to 15px horizontal padding */
    }

    .eco-packaging .sustainability-divider {
        width: 110%;
        margin: 0px;  /* Half of the desktop margin */
    }
}
