.scrolling-marquee {
    height: 120px;
    background-color: #FFFEF1;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* Remove margin-top */
}

.scrolling-marquee::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #00443B;
}

.marquee-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    animation: scroll 20s linear infinite;
}

.marquee-content span {
    color: #00443B;
    font: var(--font-small-title);
    letter-spacing: var(--font-small-title-spacing);
    text-transform: uppercase;
    margin: 0 20px;
}

.circle {
    width: 17px;
    height: 17px;
    background-color: #00443B;
    border-radius: 50%;
    margin: 0 20px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Make marquee less tall on mobile devices */
@media screen and (max-width: 768px) {
    .scrolling-marquee {
        height: 70px;
    }
    
    /* Make text smaller on mobile */
    .marquee-content span {
        font-size: 18px;
        letter-spacing: 0.5px;
    }
    
    /* Make circle smaller on mobile */
    .circle {
        width: 12px;
        height: 12px;
    }
}

