.three-column-section {
    background-color: #FFFEF1;
    box-sizing: border-box;
    width: 100%;
    min-height: 550px;  /* Reduced from 800px */
    display: flex;
    justify-content: center;  /* Changed from align-items: center */
    align-items: center;
    position: relative;  /* Added to make absolute positioning work */
}

/* This is the row that holds the 3 columns + 2 dividers in a single row on desktop */
.columns-row {
    display: flex;
    flex-wrap: nowrap;   /* never wrap columns */
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

/****************************************************************
 *  DESKTOP STYLES (>= 1118px): 
 *  - Force 3 columns side by side. 
 *  - Dividers in between at 1px each
 ****************************************************************/
@media (min-width: 1118px) {
    .columns-row {
        display: flex;
        align-items: center;  /* Changed from stretch */
        justify-content: center;
        width: 100%;
        padding: 40px;
        position: relative;
        margin: auto 0;  /* Changed to only handle horizontal auto margins */
        margin-bottom: 0;  /* Removes bottom margin to allow disclaimer spacing */
    }

    .column {
        flex: 1;
        max-width: 33%;
        box-sizing: border-box;
        padding: 30px 30px 60px;  /* Added equal top padding */
        text-align: center;
        position: relative;  /* Establish positioning context */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;  /* Align content to top */
        align-items: center;
    }

    .divider {
        flex: 0 0 1px;
        width: 1px;
        min-height: 500px;  /* Reduced height */
        background-color: #00443B;
        margin: 0;     /* removed the top/bottom margin completely */
        margin-top: 30px;  /* Match column top padding */
        align-self: flex-start;  /* changed from center to align from top */
    }

    .column-content {
        z-index: 1;     /* match column z-index */
        flex: 1;  /* Allow content to take available space */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;  /* Start content from top */
    }

    /* Make the images smaller so we don't overflow. Adjust as needed */
    .column-image {
        width: auto;
        height: 200px; 
        object-fit: contain;
        margin: 0 0 30px 0;  /* Removed top margin */
    }

    .columns-row {
        position: relative; /* for absolute positioning of dividers */
    }

    .arrow {
        display: none;
    }

    .desktop-disclaimer {
        position: absolute;
        bottom: 50px;
        left: 0;
        width: 100%;
        text-align: center;
        font: var(--font-small-body);
        color: #00443B;
    }

    .mobile-disclaimer {
        display: none;
    }
}

/****************************************************************
 *  MOBILE STYLES (<= 1117px):
 *  - Switch to one column per row 
 *  - Dividers become horizontal or just remove them
 ****************************************************************/
@media (max-width: 1117px) {
    .columns-row {
        flex-wrap: wrap;   /* or column direction. We'll just stack them. */
        flex-direction: column;
    }

    .column {
        flex: 0 0 auto; 
        width: 100%; 
        box-sizing: border-box;
        padding: 20px 20px;
        min-height: auto;
        text-align: center;
        margin-bottom: 20px; /* spacing between columns */
    }

    .column-image {
        height: 180px;
        margin: 20px 0;
    }

    .divider {
        width: 100%;
        height: 1px;
        background: #00443B;
        margin: 0 auto 20px auto; /* horizontal divider between columns */
        flex: 0 0 auto;
    }

    .mobile-disclaimer {
        display: block;
        font-family: 'SimplonMono', monospace;
        font-size: 14px;
        color: #00443B;
        padding-top: 30px;
    }

    .desktop-disclaimer {
        display: none;
    }

    .mobile-disclaimer {
        display: block; 
        font-size: 14px;
        margin-top: 10px;
    }

    .desktop-only {
        display: none;
    }

    .column-subtext-link {
        width: 240px;
        height: 44px;
        margin: 20px auto;
    }

    .column-subtext {
        font-size: 14px;
    }

    .column-button-circle {
        width: 90%;
        height: 100%;
        border-width: 1px;
    }

    /* Remove any remaining arrow styles */
    .arrow {
        display: none;
    }
}

/****************************/
/* Shared column styling    */
/****************************/
.column-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
}

.column-title {
    font: var(--font-small-title);
    margin: 10px 0 20px 0;
    color: #00443B;
    text-transform: uppercase;
}

.column-text {
    font: var(--font-medium-body);
    color: #00443B;
    margin: 0 0 30px 0;  /* Increased bottom margin for better spacing */
    min-height: 100px;   /* Increased minimum height to accommodate more text */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Unified button styles */
.column-subtext-link {
    background: transparent;
    border: none;
    color: #00443B;
    font: var(--font-button);
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 280px;
    height: 50px;
    text-decoration: none;
    margin: 0 auto;
}

.column-subtext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.column-button-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 120%;
    border: 1px solid #00443B;
    border-radius: 30px;
    transition: background-color 0.5s ease;
}

.column-subtext-link:hover .column-button-circle {
    background-color: rgba(157, 189, 151, 0.9);
    border-color: rgba(157, 189, 151, 0.9);
}

/* iPad-specific styles */
@media (min-width: 768px) and (max-width: 1117px) {
    .columns-row {
        flex-direction: column;
        padding: 20px 40px;  /* Add more horizontal padding */
    }

    .column {
        width: 100%;
        padding: 30px 20px;
        margin-bottom: 30px;  /* More space between columns */
    }

    .column-image {
        height: 250px;  /* Larger images for iPad */
        width: auto;
        margin: 30px 0;  /* More vertical spacing */
    }

    .divider {
        width: 98%;  /* Increased from 90% to stretch nearly full width */
        margin: 10px auto 40px;  /* More space around dividers */
    }
}

/* iPad Pro specific adjustments */
@media (min-width: 1024px) and (max-width: 1117px) {
    .column-image {
        height: 280px;  /* Even larger images for iPad Pro */
    }

    .divider {
        width: 99%;  /* Increased from 95% to stretch almost completely */
        margin: 15px auto 45px;  /* Slightly more spacing for the longer line */
    }
}
