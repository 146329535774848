.privacy-policy-container {
    font-family: 'Work Sans', sans-serif;
    line-height: 1.6;
    padding-top: 96px;
    list-style-type: none;
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #FFFFFF;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .header-offset {
    padding-top: 96px;
  }
  
  .privacy-policy-container h1 {
    font-size: 2em;
    color: #27453E;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5em;
    color: #27453E;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .privacy-policy-container p {
    margin-bottom: 15px;
  }
  
  .privacy-policy-container a {
    color: #27453E;
    text-decoration: none;
    font-weight: 500;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy-container ul {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-policy-container ul li {
    list-style-type: none;
    margin-bottom: 8px;
  }
  
  .privacy-policy-container strong {
    color: #000;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-policy-container h1 {
      font-size: 1.8em;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.3em;
    }
  }
  