.sustainable-farming-container {
    position: relative;
    height: 70vh; /* Adjusted height for taller banner */
    min-height: 600px; /* Added minimum height for desktop */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    color: white;
    overflow: hidden;
}

.sustainable-farming-content {
    max-width: 1400px;
    width: 90%;
    max-height: 800px;
    padding: 0;
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0 auto;
}

.sustainable-farming-content h1 {
    font: var(--font-medium-title);
    margin-bottom: 40px;
    text-align: center; /* Ensure heading is center-justified */
}

.sustainable-farming-container .RoundedButton {
    margin-top: 20px;
}

.sustainable-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video fills the background while maintaining aspect ratio */
    z-index: 0;
}

.sustainable-farming-content .desktop-line {
    display: block;
    font: var(--font-large-body);
    letter-spacing: var(--font-large-body-spacing);
    max-width: 100%; /* Ensure full width */
    margin: 0 auto; /* Center the text */
}

.sustainable-farming-content .mobile-line {
    display: none; /* Hide mobile lines by default */
    font-family: 'Work Sans', sans-serif; /* Ensure consistent font family */
    font-size: 18px; /* Adjust font size for better readability */
    line-height: 1.6; /* Ensure proper line spacing */
}

.sustainable-button-container {
    text-align: center;
    margin-top: 60px; /* Increased space above the button */
    margin-bottom: 40px; /* Space below the button */
}

.sustainable-button-container a,
.sustainable-button-container button,


.sustainable-button-container a:hover,
.sustainable-button-container button:hover,
.sustainable-button-container .rounded-button:hover {
    color: white; /* Hover text color */
    border-color: white; /* Keep the white outline on hover */
}

.sustainable-title {
    color: white;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 22pt;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.sustainable-title-divider {
    height: 1px;
    background-color: white;
    width: 90vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;
}

/* Add iPad-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sustainable-farming-content {
        max-width: 100%; /* Adjust width for tablets */
        text-align: center; /* Ensure text is center-justified */
    }

    .sustainable-farming-content h1 {
        font-size: 26pt; /* Slightly smaller font for better fit */
        text-align: center; /* Ensure heading is center-justified */
    }
}

/* Specific adjustments for iPad Air and similar smaller tablets */
@media screen and (min-width: 768px) and (max-width: 920px) {
    .sustainable-farming-content h1 {
        font-size: 24pt; /* Further reduce font size */
        text-align: center; /* Ensure heading is center-justified */
    }
}

@media screen and (max-width: 1117px) {
    .a.rounded-button { 
        display: none;
    }
    .sustainable-farming-container {
        height: 90vh; /* Adjusted height for taller banner on mobile only */
    }

    .sustainable-farming-content {
        width: 95%; /* Adjust width for mobile */
        text-align: center; /* Ensure text is center-justified */
    }

    .sustainable-farming-content h1 {
        font-size: 15pt;
        text-align: center;
         /* Ensure heading is center-justified */
    }

    .sustainable-farming-content .desktop-line {
        display: none; /* Hide desktop lines on mobile */
        font-family: 'Work Sans', sans-serif; /* Ensure consistent font family */
        font-size: 18px; /* Adjust font size for better readability */
        line-height: 1.6; /* Ensure proper line spacing */
    }

    .sustainable-farming-content .mobile-line {
        display: block; /* Show mobile lines on mobile */
        font-family: 'Work Sans', sans-serif; /* Ensure consistent font family */
        font-size: 28px; /* Adjust font size for better readability */
        line-height: 1.6; /* Ensure proper line spacing */
    }
}
