.Franchise {
    position: relative;
  }
  
  .Franchise .BannerBackground {
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../franchise-banner-image.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  @media screen and (min-width: 1366px) {
    .Franchise .BannerBackground {
      max-height: 900px;
    }
  }
  
  .Franchise .Banner {
    position: relative;
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    background-color: rgba(0, 0, 0, 0.15);
  }
  
  .Franchise .Banner .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
    height: calc(100vh - 60px);
    min-height: calc(600px - 60px);
    max-height: calc(768px - 60px);
    padding: 100px 0;
  }
  
  .Franchise .Banner .contentWrapper .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #00443B; /* Ensure the border color matches */
    border-left: 1px solid #00443B; /* Ensure the border color matches */
    height: 100%; /* Ensure the border extends to the bottom */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .Franchise .Banner .contentWrapper .content h1 {
    margin-bottom: 60px;
    background-color: rgb(246, 243, 239); /* Changed from rgba(246, 243, 239, 0.9) to fully opaque */
    padding: 25px 45px; /* Reduced from 45px 30px */
    text-align: center;
    color: #00443B; /* Dark green color */
    font-family: 'Work Sans Medium', sans-serif; /* Changed back to Work Sans Medium */
    font-weight: normal; /* Keep normal weight with Medium font variant */
    width: 330px; /* Increased from 300px to account for wider padding */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between lines */
  }
  
  .Franchise .Banner .contentWrapper .content h1 span {
    display: block;
  }
  
  .Franchise .Banner .contentWrapper .content h1 .desktopOnly {
    display: none;
  }
  
  @media screen and (min-width: 900px) {
    .Franchise .Banner .contentWrapper {
      height: calc(100vh - 100px);
      min-height: calc(600px - 100px);
      max-height: calc(768px - 100px);
      padding: 0 60px;
    }
  
    .Franchise .Banner .contentWrapper .content h1 {
      font-size: 2rem;
      line-height: 3.25rem;
      letter-spacing: 0.3rem;
      width: 380px; /* Increased from 350px */
      padding: 40px 60px; /* Reduced from 60px 45px */
    }
  
    .Franchise .Banner .contentWrapper .content h1 .desktopOnly {
      display: inline;
    }
  }
  
  @media screen and (min-width: 1234px) {
    .Franchise .Banner .contentWrapper .content {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 900px;
      color: #F6F3EF;
    }
  
    .Franchise .Banner .contentWrapper .content h1 {
      font-size: 2.75rem;
      line-height: 4rem;
      letter-spacing: 0.4rem;
      width: 430px; /* Increased from 400px */
      padding: 55px 75px; /* Reduced from 75px 60px */
    }
  }
  
  @media screen and (min-width: 1366px) {
    .Franchise .Banner {
      max-height: 900px;
    }
  
    .Franchise .Banner .contentWrapper {
      padding: 0 80px;
      max-height: calc(900px - 100px);
    }
  
    .Franchise .Banner .contentWrapper .content h1 {
      font-size: 3.5rem;
      line-height: 4.75rem;
      letter-spacing: 0.4rem;
      width: 480px; /* Increased from 450px */
      padding: 70px 75px; /* Reduced from 90px 60px */
    }
  }
  
  /* Blurb */

.Blurb {
    background-color: #00443B;
    color: #F6F3EF;
  }
  
  .Blurb .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .Blurb .content {
    padding: 50px 20px 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Blurb .content img {
    height: 18px;
    width: auto;
    margin-bottom: 40px;
  }
  
  .Blurb .content .heading {
    font-family: 'Work Sans Medium', sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .Blurb .content p {
    max-width: 300px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 0;
  }
  
  .Blurb .content .buttonContainer {
    padding-top: 50px;
  }
  
  .Blurb .content.larger p {
    max-width: 320px;
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
  
  @media screen and (min-width: 900px) {
    .Blurb .content {
      padding: 70px 60px 80px 60px;
    }
  
    .Blurb .content img {
      height: 20px;
      margin-bottom: 60px;
    }
  
    .Blurb .content .heading {
      font-size: 0.9rem;
      margin-bottom: 30px;
    }
  
    .Blurb .content p {
      max-width: 500px;
      font-size: 1.75rem;
      line-height: 3rem;
      letter-spacing: 0.05rem;
    }
  
    .Blurb .content.larger p {
      max-width: 640px;
      font-size: 2rem;
      line-height: 3.5rem;
    }
  }
  
  @media screen and (min-width: 1234px) {
    .Blurb .contentWrapper {
      padding: 0 60px;
    }
  
    .Blurb .content {
      border-right: 1px solid #A8D5BA;
      border-left: 1px solid #A8D5BA;
      padding: 85px 60px 90px 60px;
    }
  
    .Blurb .content img {
      height: 22px;
      margin-bottom: 70px;
    }
  
    .Blurb .content .heading {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  
    .Blurb .content p {
      max-width: 520px;
      font-size: 2rem;
      line-height: 3.5rem;
      letter-spacing: 0.075rem;
    }
  
    .Blurb .content .buttonContainer {
      padding-top: 60px;
    }
  
    .Blurb .content.larger p {
      max-width: 740px;
      font-size: 2.25rem;
      line-height: 3.75rem;
      letter-spacing: 0.1rem;
    }
  }
  
  @media screen and (min-width: 1366px) {
    .Blurb .contentWrapper {
      padding: 0 80px;
    }
  
    .Blurb .content {
      padding: 100px 80px 110px 80px;
    }
  
    .Blurb .content img {
      height: 26px;
      margin-bottom: 80px;
    }
  
    .Blurb .content .heading {
      font-size: 1.1rem;
      line-height: 2.2rem;
    }
  
    .Blurb .content p {
      max-width: 600px;
      font-size: 2.25rem;
      line-height: 3.75rem;
      letter-spacing: 0.1rem;
    }
  
    .Blurb .content .buttonContainer {
      padding-top: 80px;
    }
  
    .Blurb .content.larger p {
      max-width: 800px;
      font-size: 2.5rem;
      line-height: 4rem;
    }
  }
  
  .Industry {
    background-color: #F6F3EF;
    color: #00443B;

    .contentWrapper, .captionWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .itemsWrapper {
      border-bottom: 1px solid #00443B;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;

      .item {
        width: calc(50% - 20px);

        .title {
          font-family: 'Work Sans Medium', sans-serif; /* Correctly implement WorkSansMedium font */
          text-transform: uppercase;
          letter-spacing: 0.075rem;
          font-size: 2.3rem;
          line-height: 3rem;
          margin-bottom: 10px;
          text-align: left; /* Ensure all text is left justified */
        }

        .description {
          font-family: 'Work Sans', sans-serif; /* Ensure the font is Work Sans */
          font-size: .95rem;
          line-height: 1.3rem;
          margin-bottom: 0;
          text-align: left; /* Ensure all text is left justified */
        }
      }

      .item:nth-child(odd) {
        padding: 20px 20px 20px 0;
      }

      .item:nth-child(even) {
        padding: 20px 0 20px 20px;
        border-left: 1px;
        color: #00443B;
      }

      .item:nth-child(-n + 2) {
        border-bottom: 1px;
        color: #00443B;
      }
    }

    .imageContainer {
      display: flex;
      flex-direction: column-reverse;
      border-bottom: 1px;
      color: #00443B;

      .image {
        width: 100%;
        height: calc(100vw * 0.714);
        max-height: 430px;
        background-image: url('../industry-plants.png');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
      }

      .caption {
        font-size: .95rem;
        line-height: 1.3rem;
        padding: 30px 20px;
        border-bottom: 1px;
        color: #00443B;

        .title {
          font-family: 'WorkSansMedium', sans-serif; /* Correctly implement WorkSansMedium font */
          text-transform: uppercase;
          letter-spacing: 0.075rem;
          text-align: left; /* Ensure all text is left justified */
        }

        .text {
          font-family: 'Work Sans', sans-serif; /* Ensure the font is Work Sans */
          margin-bottom: 0;
          text-align: left; /* Ensure all text is left justified */
        }
      }
    }

    @media screen and (min-width: 900px) {

      .itemsWrapper {
        justify-content: space-between;
        flex-wrap: nowrap;
  
        .item {
          width: 25%;
  
          .title {
            font-size: 2.75rem;
            line-height: 4rem;
            margin-bottom: 15px;
          }
        }
  
        .item:nth-child(odd), .item:nth-child(even) {
          padding: 45px 20px 30px 20px;
          border-left: 1px solid #00443B;
          color: #00443B;
        }
  
        .item:nth-child(-n + 2) {
          border-bottom: none;
        }

        .item:first-child {
          width: 25%;
          border-left: none;
        }
      }
  
      .imageContainer {
        display: block;
        position: relative;
        height: calc((100vw * 0.479));
        max-height: 919.67px;
  
        .image {
          position: absolute;
          height: calc(100vw * 0.479);
          max-height: 919.67px;
          margin-top: -1px;
        }
  
        .caption {
          position: relative;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          background-color: #F6F3EF;
  
          .title {
            width: 25%;
            padding: 30px;
            font-size: 1.2rem;
            line-height: 1.75rem;
            margin-bottom: 0;
            display: flex;
            align-items: left;
          }
  
          .text {
            width: 75%;
            border-left: 1px solid #00443B;
            color: #00443B;
            padding: 30px 20px;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .itemsWrapper {
        border-left: 1px solid #00443B; /* Add solid and color */
        border-right: 1px solid #00443B; /* Add solid and color */
        color: #00443B;
      }

      .imageContainer {
        .captionWrapper {
          padding: 0 60px;
        }
        .caption {
          border-left: 1px solid #00443B; /* Add solid and color */
          border-right: 1px solid #00443B; /* Add solid and color */
          color: #00443B;
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .itemsWrapper {
        .item {
          .title {
            letter-spacing: 0.3rem;
            font-size: 3.5rem;
            line-height: 4.5rem;
            margin-bottom: 20px;
          }
  
          .description {
            font-size: 1.2rem;
            line-height: 1.75rem;
          }
        }

        .item:nth-child(odd), .item:nth-child(even) {
          padding-top: 60px;
        }
      }
  
      .imageContainer {
        .captionWrapper {
          padding: 0 80px;
        }
  
        .caption {  
          .title {
            font-size: 1.5rem;
            line-height: 2.25rem;
          }
  
          .text {
            font-size: 1.2rem;
            line-height: 1.75rem;
          }
        }
      }
    }
  }

  .SectionHeading {
    color: #00443B;
    background-color: #F6F3EF;
    border-bottom: 1px solid #00443B;
  }

  .SectionHeading .circle {
    display: inline-block;
    width: 16px; /* Smaller circle */
    height: 16px;
    background-color: #00443B; /* Match your design color */
    border-radius: 50%; /* Makes it a perfect circle */
    margin-right: 20px;
  }

  .SectionHeading .contentContainer {
    max-width: 1920px;
    margin: 0 auto;
  }
  .SectionHeading .content {
    height: 75px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Work Sans Medium', sans-serif;
    text-transform: uppercase;
    font-size: 0.8rem; /* Reduced from 1.1rem */
    letter-spacing: 0.1rem;
  }
  .SectionHeading .content .text {
    font-weight: bold;
  }
  @media screen and (min-width: 900px) {
    .SectionHeading .content {
      height: 100px;
      font-size: 0.2rem; /* Reduced from 1.3rem */
    }
  }
  @media screen and (min-width: 1234px) {
    .SectionHeading .contentContainer {
      padding: 0 60px;
    }
  }
  @media screen and (min-width: 1366px) {
    .SectionHeading .contentContainer {
      padding: 0 80px;
    }
    .SectionHeading .content {
      height: 120px;
      padding: 0 30px;
      font-size: 1.45rem; /* Reduced from 1.75rem */
    }
  }

  .OurStory {
    background-color: #F6F3EF;
    color: #00443B;
  }
  .OurStory .contentWrapper,
  .OurStory .imageContainerWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }
  .OurStory .imageContainer {
    padding: 0 20px;
  }
  .OurStory .imageContainer .caption {
    display: flex;
    justify-content: center;
  }
  .OurStory .imageContainer .caption p {
    max-width: 320px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.5rem;
    padding: 60px 0 45px 0;
    margin: 0;
  }
  .OurStory .imageContainer .image {
    border: 1px solid #00443B;
  }
  .OurStory .imageContainer .image img {
    width: 100%;
    height: auto;
  }
  .OurStory .content .itemsWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 20px 30px 20px;
  }
  .OurStory .content .itemsWrapper .item {
    padding: 20px 0;
    border-top: 1px solid #00443B;
  }
  .OurStory .content .itemsWrapper .item:first-child {
    border-top: none;
  }
  .OurStory .content .itemsWrapper .item p {
    font-size: 0.95rem;
    line-height: 1.3rem;
    margin-bottom: 0;
  }
  @media screen and (min-width: 900px) {
    .OurStory .imageContainer {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border-bottom: 1px solid #00443B;
    }
    .OurStory .imageContainer .caption {
      align-items: center;
      width: 50%;
    }
    .OurStory .imageContainer .caption p {
      padding: 0;
      font-size: 1.75rem;
      line-height: 3rem;
      letter-spacing: 0.05rem;
    }
    .OurStory .imageContainer .image {
      width: 50%;
      border: none;
      border-left: 1px solid #00443B;
    }
    .OurStory .content .itemsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
    }
    .OurStory .content .itemsWrapper .item {
      padding: 40px 20px 50px 20px;
      border-top: none;
      border-left: 1px solid #00443B;
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .OurStory .content .itemsWrapper .item:first-child {
      border-left: none;
    }
    .OurStory .content .itemsWrapper .item p {
      max-width: 200px;
      font-size: 0.95rem;
      line-height: 1.3rem;
      margin-bottom: 0;
      text-align: center;
    }
  }
  @media screen and (min-width: 1234px) {
    .OurStory .contentWrapper,
    .OurStory .imageContainerWrapper {
      padding: 0 60px;
    }
    .OurStory .content,
    .OurStory .imageContainer {
      border-left: 1px solid #00443B;
      border-right: 1px solid #00443B;
    }
    .OurStory .imageContainer .caption p {
      font-size: 2.25rem;
      line-height: 3.75rem;
      letter-spacing: 0.1rem;
    }
    .OurStory .content .itemsWrapper .item {
      padding: 40px 20px 60px 20px;
    }
    .OurStory .content .itemsWrapper .item p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  @media screen and (min-width: 1366px) {
    .OurStory .contentWrapper,
    .OurStory .imageContainerWrapper {
      padding: 0 80px;
    }
    .OurStory .imageContainer .caption p {
      font-size: 2.5rem;
      line-height: 4rem;
    }
    .OurStory .content .itemsWrapper .item {
      padding: 60px 20px 75px 20px;
    }
    .OurStory .content .itemsWrapper .item p {
      max-width: 240px;
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }

  /* Investment */
  .Investment {
    background-color: #F6F3EF; /* $v2GreenPale */
    color: #00443B; /* $v2GreenDark */
  }
  
  .Investment .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .Investment .content {
    font-size: 0.95rem;
    line-height: 1.3rem;
    padding: 30px 20px 0 20px;
    text-align: left; /* Add explicit left alignment */
  }
  
  .Investment .content .itemsWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  .Investment .content .itemsWrapper .item {
    padding: 20px 0;
    border-bottom: 1px solid #00443B; /* $v2GreenDark */
    text-align: left; /* Add explicit left alignment */
  }
  
  .Investment .content .itemsWrapper .item:last-child {
    border-bottom: none;
  }
  
  .Investment .content .itemsWrapper .item .label {
    text-transform: uppercase;
    margin-right: 8px;
    font-family: 'Work Sans Medium', sans-serif; /* Replace if using a custom font */
    font-weight: bold; /* Add this line */
  }
  
  .Investment .imageContainer {
    padding: 20px;
    border-top: 1px solid #00443B; /* $v2GreenDark */
    border-bottom: 1px solid #00443B; /* $v2GreenDark */
  }
  
  .Investment .imageContainer .image1 {
    border: 1px solid #00443B; /* $v2GreenDark */
  }
  
  .Investment .imageContainer .image1 img {
    width: 100%;
    height: auto;
  }
  
  .Investment .imageContainer .image2,
  .Investment .imageContainer .image3 {
    display: none;
  }
  
  @media screen and (min-width: 900px) {
    .Investment .content {
      padding: 0;
    }
  
    .Investment .content .heading {
      display: flex;
      align-items: center;
      padding: 30px 20px;
      text-align: left; /* Add explicit left alignment */
    }
  
    .Investment .content .heading p {
      margin: 0;
      text-align: left; /* Add explicit left alignment */
    }
  
    .Investment .content .itemsWrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }
  
    .Investment .content .itemsWrapper .item {
      width: 50%;
      padding: 30px 20px;
      border-top: 1px solid #00443B; /* $v2GreenDark */
      border-bottom: none;
    }
  
    .Investment .content .itemsWrapper .item:nth-child(even) {
      border-left: 1px solid #00443B; /* $v2GreenDark */
    }
  
    .Investment .imageContainer {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  
    .Investment .imageContainer .image1 {
      display: none;
    }
  
    .Investment .imageContainer .image2,
    .Investment .imageContainer .image3 {
      display: block;
      width: 50%;
      height: calc(50vw * 0.897);
      max-height: 861.11px;
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .Investment .imageContainer .image2 {
      background-image: url('../investment-and-fees-2.png'); /* Update path */
    }
  
    .Investment .imageContainer .image3 {
      background-image: url('../investment-and-fees-3.png'); /* Update path */
      border-left: 1px solid #00443B; /* $v2GreenDark */
    }

    .Investment .content .itemsWrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .Investment .content .itemsWrapper .item {
      width: 50%;
      padding: 30px 20px;
      box-sizing: border-box;
    }
    .Investment .content .itemsWrapper .item:nth-child(even) {
      border-left: 1px solid #00443B;
    }
    .Investment .content .itemsWrapper .item:nth-child(-n+2) {
      border-top: 1px solid #00443B;
    }
  }
  
  @media screen and (min-width: 1234px) {
    .Investment .contentWrapper {
      padding: 0 60px;
    }
  
    .Investment .content {
      border-left: 1px solid #00443B; /* $v2GreenDark */
      border-right: 1px solid #00443B; /* $v2GreenDark */
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  
  @media screen and (min-width: 1366px) {
    .Investment .contentWrapper,
    .Investment .imageContainerWrapper {
      padding: 0 80px;
    }
  
    .Investment .content {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }

  .NextSteps {
  background-color: #F6F3EF; /* $v2GreenPale */
  color: #00443B; /* $v2GreenDark */
  border-bottom: 1px solid #00443B;
}

.NextSteps .contentWrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.NextSteps .content .stepsWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;
}

.NextSteps .content .stepsWrapper .step {
  width: 100%;
  border-bottom: 1px solid #00443B;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.NextSteps .content .stepsWrapper .step .num {
  font-family: 'Work Sans Medium', sans-serif; /* @include WorkSansMedium */
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2.25rem;
  padding: 20px 20px 20px 0;
  border-right: 1px solid #00443B;
}

.NextSteps .content .stepsWrapper .step .text {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: .95rem;
  line-height: 1.3rem;
  padding: 30px 20px;
  text-align: left; /* Change text alignment to left */
}

.NextSteps .content .stepsWrapper .step:last-child {
  border-bottom: none;
}

@media screen and (min-width: 900px) {
  .NextSteps .content .stepsWrapper {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
  }

  .NextSteps .content .stepsWrapper .step {
    width: 25%;
    height: auto; /* Remove fixed height */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 20px;
    border: none;
    border-right: 1px solid #00443B;
    border-bottom: 1px solid #00443B;
  }

  /* Reset previous border rules */
  .NextSteps .content .stepsWrapper .step:nth-child(-n + 4) {
    border-bottom: 1px solid #00443B;
  }

  .NextSteps .content .stepsWrapper .step:nth-child(n + 5) {
    border-bottom: none;
  }

  .NextSteps .content .stepsWrapper .step:nth-child(4n) {
    border-right: none;
  }

  /* Rest of the styles remain the same */
  .NextSteps .content .stepsWrapper .step .num {
    padding: 0;
    border-right: none;
    margin-bottom: 30px;
  }

  .NextSteps .content .stepsWrapper .step .text {
    padding: 0;
  }

  .NextSteps .content .stepsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .NextSteps .content .stepsWrapper .step {
    width: 25%; /* Keep 25% width */
    flex: 0 0 25%; /* Prevent flex items from growing or shrinking */
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 20px;
    border: none;
    border-right: 1px solid #00443B;
  }

  /* Force two rows of four items */
  .NextSteps .content .stepsWrapper .step:nth-child(-n + 4) {
    border-bottom: 1px solid #00443B;
  }

  .NextSteps .content .stepsWrapper .step:nth-child(4n) {
    border-right: none;
  }

  /* Ensure even spacing */
  .NextSteps .content .stepsWrapper {
    align-items: stretch;
    min-height: 600px; /* Ensure enough space for two rows */
  }
}

@media screen and (min-width: 1234px) {
  .NextSteps .contentWrapper {
    padding: 0 60px;
  }

  .NextSteps .content {
    border-left: 1px solid #00443B;
    border-right: 1px solid #00443B;
  }

  .NextSteps .content .stepsWrapper .step {
    height: 270px;
  }

  .NextSteps .content .stepsWrapper .step .num {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  .NextSteps .content .stepsWrapper .step .text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media screen and (min-width: 1366px) {
  .NextSteps .contentWrapper {
    padding: 0 80px;
  }

  .NextSteps .content .stepsWrapper .step {
    height: 300px;
  }

  .NextSteps .content .stepsWrapper .step .num {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  .NextSteps .content .stepsWrapper .step .text {
    font-size: 1.2rem;
    line-height: 1.75rem;
  }
}

/* Import SimplonMono fonts */
@font-face {
  font-family: 'SimplonMono';
  src: url('../SimplonMono-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SimplonMono';
  src: url('../SimplonMono-RegularItalic.otf') format('otf');
  font-weight: bold;
  font-style: normal;
}

/* Apply SimplonMono fonts */
body {
  font-family: 'SimplonMono', monospace;
}

/* FAQ Styles */
.FranchiseFAQ {
  background-color: #F6F3EF;
  color: #00443B;
}

.FranchiseFAQ .contentWrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.FranchiseFAQ .content {
  padding: 0 20px;
}

/* Update FAQ item styles to fix border consistency */
.FranchiseFAQ .content .faqItem {
  border-bottom: 1px solid #00443B;
  position: relative;
  display: flex;
  flex-direction: column;
}

.FranchiseFAQ .content .faqItem .header {
  width: 100%;
  display: flex;
  align-items: center; /* Change to center for vertical alignment */
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
  color: inherit;
  min-height: 80px; /* Ensure consistent height for chevron centering */
}

.FranchiseFAQ .content .faqItem .chevronWrapper {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; /* Fixed height for chevron container */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  align-self: center; /* Center vertically in header */
}

/* Left border of chevron container */
.FranchiseFAQ .content .faqItem::before {
  content: '';
  position: absolute;
  left: 40px; /* Width of chevron container */
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #00443B;
}

/* Right border of entire component */
.FranchiseFAQ .content .faqItem::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0px;
  background-color: #00443B;
}

.FranchiseFAQ .content .faqItem .chevronWrapper .panelIcon {
  width: 100%; /* Fill the padding box */
  height: 20px; /* Fixed height to maintain aspect ratio */
  object-fit: contain; /* Maintain aspect ratio */
  transition: transform 0.3s ease;
}

.FranchiseFAQ .content .faqItem .chevronWrapper .panelIcon.rotated {
  transform: rotate(180deg);
}

.FranchiseFAQ .content .faqItem .title {
  flex: 1;
  padding: 20px;
  padding-left: 20px; /* Consistent left padding */
  margin: 0;
  font-family: 'Work Sans Medium', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  font-weight: bold; /* Make the question text bold */
}

.FranchiseFAQ .content .faqItem .header .title {
  flex: 1;
  padding: 30px 20px;
  padding-left: 20px;
  margin: 0;
  font-family: 'Work Sans Medium', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
}

.FranchiseFAQ .content .faqItem .answer-wrapper {
  overflow: hidden;
  transition: height 0.3s ease;
  height: 0;
}

.FranchiseFAQ .content .faqItem .answer-wrapper.expanded {
  height: auto;
}

.FranchiseFAQ .content .faqItem .answer {
  padding: 0 60px 30px 60px; /* 60px left = chevron width (40px) + title padding-left (20px) */
  position: relative;
  text-align: left;
  margin: 0; /* Remove any margins */
}

.FranchiseFAQ .content .faqItem .answer p {
  margin: 0;
  padding: 0;
  text-align: left;
}

/* Remove any conflicting border styles */
.FranchiseFAQ .content .faqItem .chevronWrapper::after,
.FranchiseFAQ .content .faqItem .answer::after {
  display: none;
}

@media screen and (min-width: 900px) {
  .FranchiseFAQ .content {
    padding: 0;
  }

  .FranchiseFAQ .content .faqItem .header {
    flex-direction: row;
  }

  .FranchiseFAQ .content .faqItem .title {
    padding: 20px;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .FranchiseFAQ .content .faqItem .title .title {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper .panelIcon {
    width: 20px;
  }

  .FranchiseFAQ .content .faqItem .answer {
    padding: 0 60px 30px 60px;
    margin: 0;
  }

  .FranchiseFAQ .content .faqItem .answer {
    padding: 0 60px 30px 60px; /* Align left padding with title */
    position: relative;
    text-align: left;
    margin: 0; /* Remove any margins */
  }

  .FranchiseFAQ .content .faqItem .answer p {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

@media screen and (min-width: 1234px) {
  .FranchiseFAQ .contentWrapper {
    padding: 0 60px;
  }

  .FranchiseFAQ .content {
    border-left: 1px solid #00443B;
    border-right: 1px solid #00443B;
  }

  .FranchiseFAQ .content .faqItem .title {
    font-size: 1.2rem;
    line-height: 1.75rem;
  }

  .FranchiseFAQ .content .faqItem .title .title {
    font-size: 1.2rem;
    line-height: 1.75rem;
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper .panelIcon {
    width: 24px;
  }

  .FranchiseFAQ .content .faqItem .answer {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media screen and (min-width: 1366px) {
  .FranchiseFAQ .contentWrapper {
    padding: 0 80px;
  }

  .FranchiseFAQ .content .faqItem .title {
    padding: 30px 20px;
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper {
    width: 40px; /* Keep consistent width */
    padding: 12px; /* Slightly larger padding for bigger viewport */
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper .panelIcon {
    height: 24px; /* Slightly larger for bigger viewport */
  }

  .FranchiseFAQ .content .faqItem .title .title {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper {
    width: 90px;
    padding: 30px 0;
  }

  .FranchiseFAQ .content .faqItem .chevronWrapper .panelIcon {
    width: 30px;
  }

  .FranchiseFAQ .content .faqItem .answer {
    padding: 0 90px 40px 60px; /* Keep left padding at 60px to align with question */
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.75rem;
  }

  .FranchiseFAQ .content .faqItem .answer {
    padding: 0 90px 40px 60px; /* Align left padding with title */
    position: relative;
    text-align: left;
    margin: 0; /* Remove any margins */
  }

  .FranchiseFAQ .content .faqItem .answer p {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

.FranchiseFooter {
  background-color: #00443B; /* $v2GreenDark */
  color: #F6F3EF; /* $v2GreenPale */
  padding-bottom: 30px;

  .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    .blurb {
      padding: 50px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      img {
        height: 18px;
        width: auto;
        margin-bottom: 40px;
      }

      p {
        padding: 0 20px;
        text-align: center;
        max-width: 340px;
        font-size: 1.2rem;
        line-height: 2rem;
        margin: 0;
      }
  
      .buttonContainer {
        padding-top: 50px;
      }
    }

    .itemWrapper {
      padding: 0 20px;

      .item {
        border-top: 1px solid #F6F3EF; /* $v2GreenPale */
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        p, .phone, .email {
          text-align: center;
          font-size: .95rem;
          line-height: 1.5rem;
        }

        p {
          max-width: 260px;
          margin-bottom: 30px;
        }
      }
    }
  }

  .subText {
    padding-top: 30px;

    p {
      margin: 0 auto;
      font-size: .65rem;
      line-height: 1rem;
      text-align: center;
      padding: 0 20px;
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      .blurb {
        padding: 70px 60px;
    
        img {
          height: 20px;
          margin-bottom: 60px;
        }

        p {
          max-width: 640px;
          font-size: 1.75rem;
          line-height: 3rem;
          letter-spacing: 0.05rem;
        }
      }

      .itemWrapper {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .item {
          flex: 1;
          border-right: 1px solid #F6F3EF; /* $v2GreenPale */
          padding: 40px 30px;
        }

        .item:last-child {
          border-right: none;
        }
      }
    }

    .subText {
      border-top: 1px solid #F6F3EF; /* $v2GreenPale */

      p {
        padding: 0 30px;
        max-width: 900px;
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .contentWrapper {
      padding: 0 60px;
    }

    .content {
      border-left: 1px solid #F6F3EF; /* $v2GreenPale */
      border-right: 1px solid #F6F3EF; /* $v2GreenPale */

      .blurb {
        padding: 85px 60px;
    
        img {
          height: 22px;
          margin-bottom: 70px;
        }

        p {
          max-width: 740px;
          font-size: 2rem;
          line-height: 3.5rem;
          letter-spacing: 0.075rem;
        }
      }

      .itemWrapper {
        .item {
          padding: 50px 30px;

          p, .phone, .email {
            font-size: 1rem;
          }

          p {
            max-width: 280px;
          }
        }
      }
    }

    .subText {
      p {
        max-width: 1234px;
        padding: 0 90px;
        font-size: .75rem;
        line-height: 1.1rem;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .contentWrapper {
      padding: 0 80px;
    }

    .content {
      .blurb {
        padding: 100px 80px;
    
        img {
          height: 26px;
          margin-bottom: 80px;
        }

        p {
          max-width: 830px;
          font-size: 2.25rem;
          line-height: 3.75rem;
          letter-spacing: 0.1rem;
        }
      }

      .itemWrapper {
        .item {
          padding: 60px 30px;

          p, .phone, .email {
            font-size: 1.2rem;
            line-height: 1.75rem;
          }

          p {
            max-width: 330px;
          }
        }
      }
    }

    .subText {
      p {
        max-width: 1366px;
        padding: 0 120px;
        font-size: .85rem;
        line-height: 1.3rem;
      }
    }
  }
}

.FranchiseFooter .content .itemWrapper .item .phone a,
.FranchiseFooter .content .itemWrapper .item .email a {
  color: inherit;
  text-decoration: none;
}