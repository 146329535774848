.rounded-button {
    display: inline-block;
    padding: 19px 34px;
    border: 1px solid #FFFEF1;
    border-radius: 50px;
    color: #FFFEF1;
    text-decoration: none;
    font-family: 'Work Sans', sans-serif;
    transition: all 0.2s ease;
}

.rounded-button:hover {
    background-color: #9DBD97;
    border-color: #9DBD97;
}
