.our-stores {
    background-color: #00443B;
    width: 100%;
    position: relative;
    padding: 40px 0;
}

.image-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 0; /* Allow container to shrink */
}

/* Title section styling */
.title-section {
    text-align: center;
    padding: 0;
    margin-top: 30px;
}

.our-stores-title {
    font: var(--font-medium-title);
    color: #FFFEF1;
    text-align: center;
    margin: 40px 0 40px 0;
    letter-spacing: 1px;
}

.title-divider {
    height: 1px;
    background-color: #FFFEF1;
    width: 80%;
    margin: 0 auto;
}

.store-divider {
    height: 1px;
    background-color: #FFFEF1; /* Match text color */
    width: 90%; /* Match sustainable divider width */
    max-width: 1400px;
    margin: 20px auto; /* Add spacing above and below */
}

.carousel-container {
    position: relative;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 10%; /* Add horizontal padding for desktop only */
}

.carousel-image-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px; /* Optional: add slight rounding to images */
}

.carousel-image-container::before {
    content: '';
    display: block;
    padding-top: 56.25%; /* 16:9 aspect ratio */
}

.image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent overflow */
    transform-style: preserve-3d; /* Helps with z-index stacking */
}

.carousel-image {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    transition: none; /* Remove default transition */
}

.carousel-nav {
    background: none;
    border: none;
    cursor: pointer;
    padding: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-nav.prev {
    left: 20px;
}

.carousel-nav.next {
    right: 20px;
}

.chevron-icon {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

.store-description {
    color: #FFFEF1;
    font: var(--font-large-body);
    letter-spacing: var(--font-large-body-letter-spacing);
    text-align: center;
    margin-top: 65px;
    white-space: pre-line; /* Respect line breaks */
    max-width: 1000px; /* Limit width to prevent unwanted line wrapping */
    margin-left: auto;
    margin-right: auto;
}

.mobile-description {
    display: none;
}

.button-container {
    font: var(--font-button);
    letter-spacing: var(--font-button-letter-spacing);
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
}

/* Animation keyframes */
@keyframes slideInRight {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

@keyframes slideInLeft {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}

@keyframes slideOutLeft {
    from { 
        transform: translateX(0);
        opacity: 1;
    }
    to { 
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideOutRight {
    from { 
        transform: translateX(0);
        opacity: 1;
    }
    to { 
        transform: translateX(100%);
        opacity: 0;
    }
}

/* Add specific animation styles for each state */
.carousel-image.enter-left, 
.carousel-image.enter-right,
.carousel-image.exit-left, 
.carousel-image.exit-right {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    will-change: transform, opacity;
    transform-origin: center center;
}

/* Clear all transforms when animation is complete */
.carousel-image:not(.enter-left):not(.enter-right):not(.exit-left):not(.exit-right) {
    transform: none !important;
}

.carousel-image.enter-left {
    z-index: 1;
    animation: slideInRight 0.3s forwards;
}

.carousel-image.enter-right {
    z-index: 1;
    animation: slideInLeft 0.3s forwards;
}

.carousel-image.exit-left {
    z-index: 0;
    animation: slideOutLeft 0.3s ease-in-out forwards;
}

.carousel-image.exit-right {
    z-index: 0;
    animation: slideOutRight 0.3s ease-in-out forwards;
}

.carousel-image.enter-left, 
.carousel-image.enter-right,
.carousel-image.exit-left, 
.carousel-image.exit-right {
    backface-visibility: hidden; /* Prevent flickering */
    -webkit-backface-visibility: hidden;
    will-change: transform, opacity; /* Optimize for animation */
}

/* Media query for desktop and larger screens */
@media screen and (min-width: 768px) {
    .carousel-container {
        padding: 0 5%; /* Adjust horizontal padding for desktop */
    }
    
    .image-wrapper {
        /* Ensure the image wrapper respects the container padding */
        max-width: 100%;
    }

    .desktop-description {
        white-space: pre-line; /* Ensure line breaks are respected */
        display: block;
        word-break: keep-all; /* Prevent words from breaking */
        overflow-wrap: normal;
    }
}

@media screen and (max-width: 767px) {
    .store-divider {
        height: 1px;
        background-color: #FFFEF1; /* Match text color */
        width: 90%; /* Match sustainable divider width */
        margin: 20px auto; /* Add spacing above and below */
    }
    
    .our-stores-title {
        font-size: 25pt;
        margin: 0 0 30px 0;
    }

    .title-section {
        margin-top: 0px; /* Reduced from 20px to 10px */
    }

    .chevron-icon {
        width: 24px;
        height: 24px;
    }

    .carousel-image-container::before {
        padding-top: 115%; /* Much taller aspect ratio for mobile */
    }

    .carousel-image {
        width: 140%;
        height: 115%;
        object-fit: cover;
        object-position: center;
    }
    
    /* Override transform for mobile only when not animating */
    .carousel-image:not(.enter-left):not(.enter-right):not(.exit-left):not(.exit-right) {
        transform: translate(-20%, -7.5%) !important;
    }
    
    /* Keep the transform during animation */
    .carousel-image.enter-left,
    .carousel-image.enter-right {
        animation-fill-mode: forwards;
    }
    
    /* Combine transforms for animations on mobile */
    @keyframes slideInRightMobile {
        from { transform: translate(calc(-20% + 100%), -7.5%); }
        to { transform: translate(-20%, -7.5%); }
    }
    
    @keyframes slideInLeftMobile {
        from { transform: translate(calc(-20% - 100%), -7.5%); }
        to { transform: translate(-20%, -7.5%); }
    }
    
    @keyframes slideOutLeftMobile {
        from { transform: translate(-20%, -7.5%); opacity: 1; }
        to { transform: translate(calc(-20% - 100%), -7.5%); opacity: 0; }
    }
    
    @keyframes slideOutRightMobile {
        from { transform: translate(-20%, -7.5%); opacity: 1; }
        to { transform: translate(calc(-20% + 100%), -7.5%); opacity: 0; }
    }
    
    /* Apply mobile specific animations */
    .carousel-image.enter-left {
        animation-name: slideInRightMobile;
    }
    
    .carousel-image.enter-right {
        animation-name: slideInLeftMobile;
    }
    
    .carousel-image.exit-left {
        animation-name: slideOutLeftMobile;
    }
    
    .carousel-image.exit-right {
        animation-name: slideOutRightMobile;
    }

    .button-container {
        margin-top: 60px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .title-section {
        text-align: center;
        padding: 0;
        padding-top: 0; /* Remove additional top padding */
    }
    
    .our-stores-title {
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;
        font-size: 15pt;
        color: #FFFEF1;
        text-align: center;
        margin: 20px 0 35px 0;
        letter-spacing: 1px;
    }
    
    .title-divider {
        height: 1px;
        background-color: #FFFEF1;
        width: 85%;
        margin: 0 auto;
    }

    .desktop-description {
        display: none;
    }
    
    .mobile-description {
        display: block;
        font-size: 25px;
        margin-top: 42px;
        line-height: 1.6;
    }
    
    .carousel-container {
        padding: 0 24px; /* Add horizontal padding on mobile */
    }
    
    .carousel-image-container {
        margin-bottom: 20px; /* Add padding below the image */
    }

    .our-stores {
        padding: 25px 0; /* Reduced from 40px to 20px */
    }

    /* Target the button more specifically with more appropriate sizing */
    .button-container a,
    .button-container button,
    .button-container .rounded-button {
        font-size: 14px !important; /* Increased from 14px */
        font-weight: 500;
        padding: 15px 24px !important; /* Increased horizontal padding */
        transform: none; /* Remove scaling */
        margin: 0 auto;
        display: inline-block;
    }
    
    /* Remove container scaling */
    .button-container {
        transform: none;
        margin-top: 35px;
        margin-bottom: 25px;
    }
}
