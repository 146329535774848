/* Base row styles */
.sustainability-row {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 2;
    background-color: #FFFEF1;
}

.sustainability-row-divider {
    width: 100%;
    height: 1px;
    background-color: #00443B; /* Green stroke color */
    margin: 0;
    display: none; /* Hide this divider since we're using border-top on all rows */
}

.sustainability-row-content {
    display: flex;
    padding: 35px;
    padding-top: 25px; /* Adjust to match divider position */
    padding-left: 35px; /* Add left padding */
    box-sizing: border-box;
}

.sustainability-row-image {
    flex: 0 0 45vw; /* Reduce from 50vw to 45vw */
    padding-right: 35px;
    padding-top: 0; /* Ensure no top padding */
    margin-top: 0; /* Ensure no top margin */
    width: 45vw; /* Match the flex basis */
    max-width: 45vw; /* Match the width */
}

.sustainability-row-image img {
    max-width: 100%;
    width: 100%; /* Ensure image takes full width of container */
    display: block;
    height: auto;
    margin-top: 0; /* Ensure no top margin on image */
    vertical-align: top; /* Align to top */
    object-fit: cover; /* Ensure image fills the container */
}

.sustainability-row-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%; /* Ensure full width */
    position: relative; /* Add this */
    padding-top: 0; /* Add this to remove top spacing */
}

.sustainability-row-text-divider {
    height: 1px;
    background-color: #00443B;
    width: calc(100% - 10px);
    margin: 0 0 31px 0; /* Increased from 25px to 31px */
}

/* Text styling */
.sustainability-row-title {
    font: var(--font-small-title);
    letter-spacing: var(--font-small-title-spacing);
    color: #00443B;
    margin: 0 0 31px 0; /* Increased from 25px to 31px */
    margin-top: 0; /* Remove top margin since we have margin-bottom on divider */
    text-align: left; /* Ensure left alignment */
    width: 100%; /* Ensure full width */
}

.sustainability-row-subtitle {
    font: var(--font-medium-body);
    letter-spacing: var(--font-medium-body-letter-spacing);
    color: #00443B;
    margin: 0;
    text-align: left; /* Ensure left alignment */
    width: 100%; /* Ensure full width */
    padding-left: 0; /* No padding on left */
    margin-top: 0; /* Remove top margin since we're using bottom margin on title */
    max-width: 90%;
}

/* Add styles to show/hide subtitles based on screen size */
.sustainability-row-subtitle.desktop {
    display: block;
}

.sustainability-row-subtitle.mobile {
    display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sustainability-row-content {
        flex-direction: column;
        padding: 25px 35px; /* Equal padding on both sides */
        align-items: center; /* Center contents horizontally */
    }
    
    .sustainability-row-image {
        flex: 0 0 auto;
        width: 100%; /* Full width of container */
        max-width: 100%;
        padding-right: 0;
        padding-bottom: 31px; /* Increased from 20px to match the 31px below divider */
        display: flex;
        justify-content: center; /* Center image horizontally */
    }

    .sustainability-row-image img {
        width: 85%; /* Slightly narrower than container */
        margin: 0 auto; /* Center the image */
    }

    .sustainability-row-text {
        width: 85%; /* Match image width */
        margin: 0 auto; /* Center text container */
        
    }

    .sustainability-row-text-divider {
        display: none; /* Hide vertical dividers on mobile */
    }

    .sustainability-row-title,
    .sustainability-row-subtitle {
        width: 100%;
        padding: 0;
        
    }

    .sustainability-row-subtitle {
        font: var(--font-medium-body);
        width: 90%; /* Changed from 110% to 105% for a more subtle extension */
        max-width: 100%; /* Match the width */
        margin-left: 0;
        padding-right: 0;
        margin: 0 auto; /* Center the subtitle horizontally */
        width: auto; /* Ensure it doesn't have a fixed width causing alignment issues */
    }

    .sustainability-row-subtitle.desktop {
        display: none;
    }

    .sustainability-row-subtitle.mobile {
        display: block;
    }

    .sustainability-row-title {
        
        text-align: center; /* Center the title horizontally */
    }

    .sustainability-row-subtitle {
        text-align: center; /* Center justify the subtitle */
    }
}

/* Add padding to first sustainability row */
.sustainability-rows-container .sustainability-row:first-child {
    padding-top: 35px;
}
