/* Desktop Styles */
.hero-banner {
  width: 100vw;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  background-color: #FFFEF1;
}

.hero-content {
  width: 100%;
  height: 100%;
  padding: 140px 0px 20px; /* Reduce bottom padding from 50px to 20px */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.video-wrapper {
  height: auto;
  width: 100%; /* Add max-width to maintain proper scaling */
  margin: 0 auto; /* Center the video container */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%; /* Maintain 16:9 aspect ratio */
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.hero-title-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  width: 100%;
  color: #FFFEF1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

.hero-title-text {
  font: var(--font-large-body);
  margin-top: 90px;
  margin-bottom: 80px; /* Increased from 40px */
  text-align: center;
  max-width: 800px; /* Prevent text from stretching too wide */
}

.hero-button {
  background: transparent;
  border: none;
  color: #FFFEF1;
  font: var(--font-button);
  letter-spacing: var(--font-button-letter-spacing);
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 330px; /* Increased from 260px */
  height: 60px;
  text-decoration: none;
  margin: 0 auto; /* Center the button horizontally */
}

.hero-button-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 2px solid #FFFEF1;
  border-radius: 30px;
  transition: background-color 0.5s ease;
}

.hero-button:hover .hero-button-circle {
  background-color: rgba(157, 189, 151, 0.9);
  border-color: rgba(157, 189, 151, 0.9);
}

.hero-button-text {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  line-height: 1;
}

.desktop-title {
  display: block;
}

.mobile-title {
  display: none;
}

/* Mobile Styles - Fixed to ensure video is visible */
@media (max-width:  1117px) {
  .hero-banner {
    width: 100vw;
    height: 177.78vw; /* 9:16 ratio */
    position: relative; /* Allow scrolling */
    top: 0;
    left: 0;
    z-index: 1; /* Ensure it doesn't interfere with other elements */
    overflow: hidden;
    background-color: transparent; /* Reset background */
  }
  
  .hero-content {
    width: 100%;
    height: 100%;
    padding: 0; /* Original mobile padding */
    box-sizing: border-box;
    display: block; /* Reset display */
  }
  
  .video-wrapper {
    height: 100%;
    width: 100%;
    display: block; /* Reset display */
    padding-bottom: 0; /* Remove aspect ratio padding */
    position: relative; /* Ensure it scrolls with the page */
    z-index: 0; /* Keep it below the title and button */
  }
  
  .hero-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }

  .hero-title-container {
    position: absolute; /* Keep it centered over the video */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensure it is above the video */
  }

  .hero-title-text {
    font-size: 27.5pt;
    margin-bottom: 50px; /* Adjusted for mobile */
  }

  .hero-button {
    font: var(--font-menu);
    width: 280px; /* Increased from 220px */
    height: 60px;
    position: relative; /* Ensure it scrolls with the page */
    z-index: 3; /* Ensure it is above other elements */
    display: inline-block; /* Ensure proper visibility */
    margin: 20px auto; /* Add spacing around the button */
  }

  .hero-button-circle {
    width: 100%;
    height: 100%;
  }

  .desktop-title {
    display: none;
  }

  .mobile-title {
    display: block;
  }
}