:root {
  
  /* font */
  --font-menu: 400 20px/normal 'Work Sans', sans-serif;
  --font-button: 500 20px/normal 'Work Sans', sans-serif;
  --font-small-body: 14px/1.4 'SimplonMono', monospace;
  --font-large-body: 400 44px/1.4 'Work Sans', sans-serif;
  --font-medium-body: 20px/1.4 'SimplonMono', monospace;
  --font-small-title: 400 26px/1.4 'Work Sans', sans-serif;
  --font-medium-title: 400 32px/1.4 'Work Sans', sans-serif;

  /* letter-spacing */
  --font-small-title-spacing: 1px;
  --font-medium-title-spacing: 1px;
  --font-menu-letter-spacing: 1px;
  --font-button-letter-spacing: 1px;
  --font-large-body-letter-spacing: 1px;
  --font-medium-body-letter-spacing: 1px;
}