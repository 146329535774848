@import './typography.css';

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 12px 0;
  font-family: 'Work Sans', sans-serif;
  margin-bottom: 0;
  z-index: 1000;
  background: #FFFEF1; /* Always have background color */
}

/* Remove home-specific different styling */
.App-header.home .logo-container img {
  filter: none; /* Remove the filter that makes it white */
}

.App-header.home {
  background-color: #FFFEF1; /* Always solid background */
}

.App-header.home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFEF1; /* Always solid background */
  z-index: -1;
}

/* No need for scrolled state transition */
.App-header.home.scrolled::before {
  background-color: #FFFEF1;
}

.nav-links {
  padding-left: 55px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-links a {
  text-decoration: none;
  color: #00443B; /* Always dark color, not white */
  font: var(--font-menu);
  letter-spacing: var(--font-menu-letter-spacing);
  text-transform: uppercase;
  position: relative;
  transition: color 0.3s ease;
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  display: block;
  margin-top: 5px;
  background: #00443B;
  transition: width 0.3s ease-in-out;
}

.nav-links a:hover::after {
  width: 100%;
}

.nav-links .franchise-link {
  text-transform: none;
}

.shop-by-location {
  padding-right: 20px;
  text-decoration: none;
  color: #00443B; /* Always dark color, not white */
  font: var(--font-menu);
  text-transform: uppercase;
  position: relative;
  transition: color 0.3s ease;
}

.shop-by-location::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  display: block;
  margin-top: 5px;
  background: #00443B;
  transition: width 0.3s ease-in-out;
}

.shop-by-location:hover::after {
  width: 100%;
}

.shop-by-location.active::after {
  width: 0;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Only keep one consistent header line definition */
.header-line {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0;
  margin: 0;
  border-bottom: 1px solid #00443B; /* Always dark color */
}

img {
  height: 75px;
  width: auto;
}

.logo-scaled {
  transform: scale(0.375);
  display: block;
  margin: 0 auto;
  transition: filter 0.3s ease;
}

.hamburger-button {
    display: none;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 30px;
    height: 22px;
    flex-direction: column;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.hamburger-button span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #00443B;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger-button.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
}

.hamburger-button.open span:nth-child(2) {
    opacity: 0;
}

.hamburger-button.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}

.mobile-menu {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 120px 40px 60px 40px;
    transform: translateX(-100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 4;
    flex-direction: column;
    gap: 40px;
    opacity: 0;
    text-align: left;
    justify-content: flex-start;
}

.mobile-menu.open {
    transform: translateX(0);
    z-index: 999;
    opacity: 1;
}

.mobile-menu a {
    color: #00443B;
    text-decoration: none;
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    padding-left: 15px;
}

.mobile-menu .franchise-link {
    text-transform: none;
    font-size: 24px;
}

.location-menu-container {
  padding-right: 55px;
  position: relative;
  font-family: 'SimplonMono', monospace;
  font-size: 24px;
  color: #00443B;
  text-align: center;
}

.location-dropdown {
  position: absolute;
  margin-top: 33px;
  right: 33%;
  top: 100%;
  background: #FFFEF1;
  width: 400px;
  text-align: center;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  border-top: none;
  z-index: 1000;
}

.location-divider {
  border-top: 1px solid #00443B;
  margin: 0 20px;
}

.location-dropdown.open {
  max-height: 1000px;
  border: 1px solid #00443B;
}

.location-dropdown p {
  display: absolute;
  color: #00443B;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  font-family: 'SimplonMono', monospace;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: background-color 0.2s ease;
}

.location-dropdown p:hover {

  cursor: pointer;
}

@media screen and (max-height: 990px) {
  .location-dropdown p {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media screen and (max-height: 800px) {
  .location-dropdown p {
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

@media screen and (max-height: 640px) {
  .location-dropdown p {
    font-size: 11px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

/* Special styles for sustainability page */
.App-header.sustainability-page {
  background-color: #8FBE97;
}

.App-header.sustainability-page .header-line {
  border-bottom: 2px solid white;
}

.App-header.sustainability-page .nav-links a,
.App-header.sustainability-page .shop-by-location {
  color: white;
}

.App-header.sustainability-page .nav-links a::after,
.App-header.sustainability-page .shop-by-location::after {
  background: white;
}

.App-header.sustainability-page .logo-container img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(832%) hue-rotate(316deg) brightness(115%) contrast(106%);
}

.App-header.sustainability-page .logo-container img.logo-scaled {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(832%) hue-rotate(316deg) brightness(115%) contrast(106%) !important;
}

.App-header.sustainability-page.scrolled .nav-links a,
.App-header.sustainability-page.scrolled .shop-by-location {
  color: white;
}

.App-header.sustainability-page.scrolled .logo-container img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(832%) hue-rotate(316deg) brightness(115%) contrast(106%);
}

.App-header.sustainability-page.scrolled .logo-container img.logo-scaled {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(832%) hue-rotate(316deg) brightness(115%) contrast(106%) !important;
}

.App-header.sustainability-page .hamburger-button span {
  background-color: white;
}

@media (max-width: 1117px) {
    .hamburger-button {
        display: flex;
        left: 15px;
        z-index: 1000;
    }
    .App-header::before {
      background-color: transparent;
    }

    .App-header.scrolled::before {
      background-color: #FFFEF1;
    }

    .mobile-menu {
        display: flex;
        background-color: #FFFEF1;
    }

    .nav-links, 
    .shop-by-location {
        display: none;
    }

    .hamburger-button span {
        background-color: #00443B; /* Always dark color */
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .header-line,
    .App-header.store-page .header-line,
    .App-header.scrolled .header-line {
/* Always dark color */
    }

    .App-header.store-page .hamburger-button span {
        background-color: #00443B;
    }

    .App-header.store-page .logo-container img {
        filter: none;
    }

    .App-header.store-page::before {
        background-color: #FFFEF1;
    }

    .App-header::before {
        background-color: transparent;
    }

    .App-header.scrolled:not(.store-page) .hamburger-button span {
        background-color: #00443B;
    }

    .App-header.store-page {
      background-color: #FFFEF1;
    }

    .App-header.store-page .hamburger-button span {
      background-color: #00443B;
    }

    .location-dropdown {
      display: none;
    }

    .App-header.sustainability-page .hamburger-button span {
      background-color: white;
    }
    
    .App-header.sustainability-page.scrolled .hamburger-button span {
      background-color: white;
    }

    .App-header.sustainability-page .logo-container img.logo-scaled {
      filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(832%) hue-rotate(316deg) brightness(115%) contrast(106%) !important;
    }
}

.App-header.scrolled:not(.store-page):not(.sustainability-page) .logo-container img {
  filter: none;
}

.App-header.scrolled .nav-links a,
.App-header.scrolled .shop-by-location {
  color: #00443B;
}

.App-header.scrolled:not(.store-page) .logo-container img {
  filter: none;
}