/* Container styling */
.dispensary-container {
    font-family: 'Work Sans', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Image section */
  .dispensary-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;  /* Make sure container is full width */
  }
  
  .dispensary-image {
    max-width: 100%;
    height: auto;
  }
  /* Header section */
  .dispensary-header {
    font-family: 'Work Sans', sans-serif;
    text-align: left;
    font: var(--font-medium-body)
  }

  .dispensary-name {
    font-family: 'Work Sans', sans-serif;
    text-align: left;
    font-size: 1.3em;
    letter-spacing: 1px;
  }
  
  
  .dispensary-header h2 {
    font-family: 'Work Sans', sans-serif;
    font-size: 0.9em;
    color: #2c3a4b;
    margin-bottom: 10px;
  }
  
  .dispensary-category {
    color: #6a7b8c;
    font-weight: bold;
    margin: 5px 0;
  }
  
  /* Menu button styling */
  .dispensary-menu-button-container {
    margin: 20px 0;
    display: flex;
    gap: 10px;
  }
  
  .dispensary-menu-button {
    padding: 10px 20px;
    font: var(--font-button);
    width: 280px;
    color: #00443B;
    border: 1px solid #00443B;
    border-radius: 50px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: center;
  }
  
  .dispensary-menu-button:hover {
    background-color: #9DBD97;
    border-color: #9DBD97;
    color: #FFFEF1;
  }

  .dispensary-menu-button.medical,
  .dispensary-menu-button.recreational {
    border-color: #00443B;
    color: #00443B;
  }
  
  .dispensary-menu-button.medical:hover,
  .dispensary-menu-button.recreational:hover {
    background-color: #9DBD97;
    border-color: #9DBD97;
    color: #FFFEF1;
  }
  
  /* Hours section */
  .dispensary-hours {
    margin-top: 20px;
    font: var(--font-medium-body);
    text-align: left;  /* Left justify hours text */
    color: #2c3a4b;
  }

.dispensary-hours h3 {
  text-align: left;  /* Left justify the header too */
}

/* Content section */
.dispensary-content {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  gap: 50px;  /* Space between columns */
}

.dispensary-left-column,
.dispensary-right-column {
  flex: 0 0 calc(50% - 20px);  /* Exactly half width minus half of gap */
  max-width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
}

.dispensary-left-column > *,
.dispensary-right-column > * {
  justify-content: center;
  display: flex-column;
  width: 100%;  /* Make children take full width of column */
}

/* Responsive layout for mobile */
@media screen and (max-width: 768px) {
  .dispensary-content {
    display: auto;
    color: #00443B;
    font-family: 'Work Sans', sans-serif;
    flex-direction: column;
    gap: 20px;
  }

  .dispensary-right-column {
    border-top: 1px solid #00443B;
    padding-top: 20px;
  }

  .dispensary-left-column,
  .dispensary-right-column {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 20px;
  }

  .dispensary-container {
    padding-top: 20px;
    scroll-margin-top: 96px; /* This helps with the smooth scroll positioning */
  }

  .dispensary-content {
    padding: 0 20px;
  }

  .dispensary-name {
    margin-top: 0;
  }
}
