.age-gate {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFEF1;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.age-gate.fade-out {
  opacity: 0;
}

.gateWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gateContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gateContainer header {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.gateContainer header .image img {
  height: 24px;
  width: auto;
}

.gateContainer h1 {
  font-family: 'Work Sans', sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  margin-bottom: 20px;
  color: #00443B;
  text-transform: none;
  letter-spacing: 0;
  max-width: 300px;
}

.gateContainer p {
  font-family: 'Work Sans', sans-serif;
  max-width: 260px;
  text-align: center;
  color: #00443B;
  font-size: 14px;
  line-height: 27px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.gateButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gateButtons button {
  font-family: 'Work Sans', sans-serif;
  height: 44px;
  width: 145px;
  border-radius: 22px;
  padding: 0 22px;
  text-transform: uppercase;
  line-height: 1.25rem;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  border: 1px solid;
  cursor: pointer;
}

.gateButtonNo {
  border: solid #00443B 1px;
  background-color: transparent;
  color: #00443B;
  margin-bottom: 20px;
}

.gateButtonYes {
  border: solid #00443B 1px;
  background-color: #00443B;
  color: #FFFEF1;
}

@media screen and (min-width: 768px) {
  .gateContainer header {
    margin-bottom: 60px;
  }
  
  .gateContainer header .image img {
    height: 30px;
  }

  .gateContainer h1 {
    font-size: 35px;
    line-height: 45px;
    max-width: 500px;
  }

  .gateButtons {
    flex-direction: row;
  }

  .gateButtonNo {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1366px) {
  .gateContainer header {
    margin-bottom: 90px;
  }
  
  .gateContainer header .image img {
    height: 45px;
  }

  .gateContainer h1 {
    font-size: 45px;
    line-height: 60px;
    max-width: 600px;
    margin-bottom: 45px;
  }

  .gateContainer p {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 45px;
  }
}
