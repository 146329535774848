.ct-cannabis-info-container {
    font-family: 'Work Sans', sans-serif;
    line-height: 1.6;
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #FFFFFF;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .header-offset {
    padding-top: 96px;
  }
  
  
  .cannabis-info-container h1,
  .cannabis-info-container h2,
  .cannabis-info-container h3,
  .cannabis-info-container h4 {
    color: #27453E;
    margin-bottom: 10px;
  }
  
  .cannabis-info-container h1 {
    font-size: 2em;
    text-align: center;
  }
  
  .cannabis-info-container h2 {
    font-size: 1.5em;
    margin-top: 20px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .cannabis-info-container h3 {
    font-size: 1.3em;
    margin-top: 15px;
  }
  
  .cannabis-info-container p {
    margin-bottom: 15px;
    text-align: left
  }
  
ul {
  list-style-type: none;
  margin: 0; /* Optional: Adjust margin */
  padding: 0; /* Optional: Adjust padding */
}
  
  .cannabis-info-container a {
    color: #27453E;
    text-decoration: none;
    font-weight: bold;
  }
  
  .cannabis-info-container a:hover {
    text-decoration: underline;
  }
  
  .cannabis-info-container strong {
    color: #000;
  }
  
  @media (max-width: 768px) {
    .cannabis-info-container {
      padding: 15px;
    }
  
    .cannabis-info-container h1 {
      font-size: 1.8em;
    }
  
    .cannabis-info-container h2 {
      font-size: 1.3em;
    }
  }
  