.two-product-view {
    padding: 0 35px 35px;
    box-sizing: border-box;
    position: relative; /* Ensure proper positioning of children */
}

.product-title {
    font: var(--font-small-title) !important;
    letter-spacing: var(--font-small-title-spacing);
    text-align: center;
    margin: 0;
    color: #00443B;
}

.caption-container {
    display: flex;
    justify-content: space-between;
    width: 85%;  /* Increased from 75% to give more room */
    margin: 0 auto;
    position: relative;
    z-index: 3;
    gap: 200px;  /* Increased from 100px to space captions much further apart */
}

.caption-left, .caption-right {
    font-family: 'SimplonMono', monospace;
    font-size: var(--font-medium-body);
    letter-spacing: var(--font-medium-body-letter-spacing);
    color: #00443B;
    width: 99%;  /* Increased from 30% to make captions wider */
    text-align: center;
    margin: 0;
    padding-top: 0; /* Removed padding-top since we're using margin-bottom on image */
    margin-top: 110px; /* Added positive margin to create consistent spacing */
}

.caption-title {
    font: var(--font-small-title);
    text-align: center;
    margin: 0 0 30px 0;  /* Increased bottom margin from 15px to 30px (2x) */
    color: #00443B;
}

.products-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 100px; /* Increased from 20px to spread products further apart */
    margin: 117px auto 17px; /* Reduced bottom margin from 117px to 17px */
    width: 85%; /* Increased from 50% to give more room for larger images */
    position: relative;
}

.product-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.product-image {
    width: 100%;
    height: auto;
    max-height: 380px;
    object-fit: contain;
    z-index: 1;
    margin-bottom: 30px; /* Reduced from 100px to create natural spacing */
}

.vertical-divider {
    width: 1px;
    height: 730px;
    background-color: #00443B;
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 2;
    display: block !important; /* Force display */
    opacity: 1; /* Ensure full opacity */
}

/* Remove the old product-dad class as it's no longer needed */
.product-dad {
    display: none;
}

@media screen and (max-width: 768px) {
    .two-product-view {
        padding: 0px 35px 35px;  /* Remove top padding on mobile */
    }

    .vertical-divider {
        display: none;
        opacity: 0; /* Hide the vertical divider on mobile */
    }

    .products-container {
        flex-direction: column;
        width: 100%;
        gap: 50px;
        margin: 20px auto;  /* Reduced from 50px to 20px */
    }

    .product-group {
        width: 100%;
        gap: 20px;
    }

    .product-image {
        width: 60%;
        margin-bottom: 30px; /* Match desktop spacing */
        padding-bottom: 100px;
    }

    .two-product-view {
        flex-direction: column;
        width: 100%;
        gap: 25px;
    }

    .caption-left, .caption-right {
        width: 120%;  /* Increased from 80% to allow for wider text */
        font-size: 16px;
        padding-top: 0; /* Remove padding since we're using margin on image */
        margin-top: 20px; /* Match desktop spacing */
    }

    .caption-title {
        font-size: 18px;
        margin-top: 0px; /* Reduced from 50px to create less space */
    }

    .product-title {
        font-size: 16pt !important;
        margin-top: -90px;
        padding-bottom: 125px !important;
    }
}
